import { Component, OnInit } from '@angular/core';
import {IImage} from '../../../../model/IImage';
import {SliderService} from '../../../../../../services';
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers:[SliderService]
})
export class SliderComponent implements OnInit {

  constructor(private _SliderService : SliderService) { }

  imageUrls: (string | IImage)[] = [];

  height: string = '500px';
  minHeight: string;
  arrowSize: string = '30px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 6666;
  stopAutoPlayOnSlide: boolean = true;
  debug: boolean = false;
  backgroundSize: string = 'cover';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  hideOnNoSlides: boolean = false;
  width: string = '100%';
  fullscreen: boolean = false;

  ngOnInit() {
        // this._SliderService.getsliderdata(this.imageUrls);   
        this.imageUrls = this._SliderService.getsliderdataStatic();         
       debugger;
      }

}

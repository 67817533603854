import { Component, OnInit, Input } from '@angular/core';
import { bannerModal } from 'src/app/common';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  @Input() navigationLinks : Array<bannerModal>;
  constructor() { }

  ngOnInit() {
  }

}


import { Component, OnInit, Input } from '@angular/core';
import {FromToDateModal} from '../../model'
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  
  isAdminMenu : boolean = false;
  
  userName: string =""; 
  
  @Input() moduleType : any;
   
  constructor() { }
  
  ngOnInit() {
    this.userName =  localStorage.getItem('userName');  
  }

}

export class eventModal {
            idEventSection : number;
            titleEventSection: string | null;
            descEventSection: string | null;
            sortDescEventSection: string | null;
            agendaEventSection: string | null;
            dateEventSection:Date ;
            ageCalculationEventSection:Date ;
            lastDateOfEventSection:Date;
            imageUrlEventSection: string | null;
            prevYearVideoUrlEventSection: string | null;
            isActiveEventSection :boolean;
            employeeId: number;
            curDate: Date;
            videoUrlEventSection: string | null;

}
import { Injectable } from '@angular/core';
import { responder, constants, keyValueModal } from 'src/app/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { eventModal } from 'src/app/modules/public/model/events.modal';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private _router: Router, private HttpClient: HttpClient, private _http: Http) { }

  _responder: (any | responder);


  getEventsStatic(obj: keyValueModal)  {
    let returnArray: Array<eventModal> = new Array<eventModal>();
    let eventobj = new eventModal();
    debugger;
    if (parseInt(obj.value) >= 1) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 6;
      eventobj.titleEventSection = '6th Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";

      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2021-10-17');
      eventobj.lastDateOfEventSection = new Date('2021-10-15');
      eventobj.imageUrlEventSection = 'assets/images/6thSpeedSkateComming.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date();
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=oIJyMKkuq00";
      returnArray.push(eventobj);
    }
    if (parseInt(obj.value) >= 2) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 5;
      eventobj.titleEventSection = '5th Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";

      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2019-07-07');
      eventobj.lastDateOfEventSection = new Date('2019-07-07');
      eventobj.imageUrlEventSection = 'assets/images/5thSpeedSkate.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date('2019-07-07');
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=oIJyMKkuq00";
      returnArray.push(eventobj);
    }
    if (parseInt(obj.value) >= 3) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 4;
      eventobj.titleEventSection = '4th  Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";

      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2018-07-01');
      eventobj.lastDateOfEventSection = new Date('2018-07-01');
      eventobj.imageUrlEventSection = 'assets/images/4thSpeedSkate.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date('2018-07-01');
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=0PHGiSNSecA";

      returnArray.push(eventobj);
    }
    if (parseInt(obj.value) >= 4) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 3;
      eventobj.titleEventSection = '3rd Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";

      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2017-07-09');
      eventobj.lastDateOfEventSection = new Date('2017-07-09');
      eventobj.imageUrlEventSection = 'assets/images/3rdSpeedSkate.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date('2017-07-09');
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=ClahHZE-PpI";

      returnArray.push(eventobj);
    }
    if (parseInt(obj.value) >= 5) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 2;
      eventobj.titleEventSection = '2nd Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";

      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2016-07-09');
      eventobj.lastDateOfEventSection = new Date('2016-07-09');
      eventobj.imageUrlEventSection = 'assets/images/2ndSpeedSkate.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date('2016-07-09');
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=Fgo8Ts16bR0";

      returnArray.push(eventobj);
    }
    if (parseInt(obj.value) >= 6) {
      let eventobj = new eventModal();
      eventobj.idEventSection = 1;
      eventobj.titleEventSection = '1st Open Kutch Speed Skating Championship';
      eventobj.descEventSection = '';
      eventobj.sortDescEventSection = "Your Child Loves To Skate? Want To Give Them Some Competition Explosure? Here's The Chance To Patricipate!";
      eventobj.agendaEventSection = 'Under The Age Of Ramaben Mohanlal Davda Physical Education Trust';
      eventobj.dateEventSection = new Date('2015-07-11');
      eventobj.lastDateOfEventSection = new Date('2015-07-11');
      eventobj.imageUrlEventSection = 'assets/images/1stSpeedSkate.jpg';
      eventobj.prevYearVideoUrlEventSection = '';
      eventobj.isActiveEventSection = true;
      eventobj.employeeId = 0;
      eventobj.curDate = new Date('2015-07-11');
      eventobj.videoUrlEventSection = "https://www.youtube.com/watch?v=nytmU4ICq6g";

      returnArray.push(eventobj);
    }
    return returnArray;
    //.....
  }


  getEvents(queryParam: Array<keyValueModal> = null): Observable<any> {
    let eventobj;
    let serviceRoute = constants.api + "/admin/event";
    if (queryParam && queryParam.length > 0) {
      for (let index = 0; index < queryParam.length; index++) {
        let element = queryParam[index];
        if (element.key == "limit") {
          if (index == 0) { serviceRoute += "?"; }
          else { serviceRoute += "&"; }
          serviceRoute += "limit=" + element.value;
        }
        else if (element.key == "id") {
          if (index == 0) { serviceRoute += "?"; }
          else { serviceRoute += "&"; }
          serviceRoute += "idEventSection=" + element.value;
        }
      }
    }

    return this._http.get(serviceRoute)
      .map((res: Response) => <any>res.json());

    /*    this.HttpClient.get(serviceRoute).subscribe((res: any) => {
          if (res['status'] == 200) {
            res.data.forEach(element => {
              eventobj = new eventModal();
              eventobj.idEventSection = element.idEventSection;
              eventobj.titleEventSection = element.titleEventSection;
              eventobj.descEventSection = element.descEventSection;
              eventobj.sortDescEventSection = element.sortDescEventSection;
              eventobj.agendaEventSection = element.agendaEventSection;
              eventobj.dateEventSection = element.dateEventSection;
              eventobj.lastDateOfEventSection = element.lastDateOfEventSection;
              eventobj.imageUrlEventSection = constants.api + '/' + element.imageUrlEventSection.replace(new RegExp(/\134/g, 'g'), '/');
              eventobj.prevYearVideoUrlEventSection = element.prevYearVideoUrlEventSection;
              eventobj.isActiveEventSection = element.isActiveEventSection;
              eventobj.employeeId = element.employeeId;
              eventobj.curDate = element.curDate;
              returnArray.push(eventobj);
            });
          }
          else {
            this._router.navigate(['/login'], { queryParams: { error: 'invalid' } });
          }
        }
        */
    //);
  }
}

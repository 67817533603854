import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ad-prev-video',
  templateUrl: './ad-prev-video.component.html',
  styleUrls: ['./ad-prev-video.component.css']
})
export class AdPrevVideoComponent implements OnInit {

  constructor() { }
  // @Input() eventId : number;
  @Input() videoSource : string;
  ngOnInit() {
  }

}

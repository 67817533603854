import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from './public-routing.module';
import{SlideshowModule} from 'ng-simple-slideshow';
import{SharedModule} from '../shared/shared.module';

import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { 
  HomepageComponent,
  BannerComponent,
  GalleryComponent,EventComponent,EventDetailComponent,EventFormComponent,StudentsComponent
 } from './component';
import { 
    SliderComponent,
    VideoComponent,
    SkillComponent,
    CoachComponent,
    ReviewAppointmentComponent,
    EventsComponent,
    PlayersSliderComponent
   } from './component/homepage/components';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RegisterComponent } from './component/students/register/register.component';
import { NewEntryComponent } from './component/students/new-entry/new-entry.component';

@NgModule({
  declarations: [
      HomepageComponent, 
      SliderComponent, 
      VideoComponent, 
      SkillComponent, 
      CoachComponent, 
      ReviewAppointmentComponent, 
      EventsComponent, 
      PlayersSliderComponent,
      BannerComponent,
      EventComponent,
      EventDetailComponent,
      EventFormComponent,
      StudentsComponent,
      GalleryComponent,
      RegisterComponent,
      NewEntryComponent
    ],
  imports: [
    
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    SlideshowModule,
    NgxHmCarouselModule,
    FormsModule,ReactiveFormsModule, 
    NgbModule,
  ]
})
export class PublicModule { }

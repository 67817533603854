export class studentModal{
    idStudents  :number;
    firstNameStudents: string;
    middleNameStudents: string;
    lastNameStudents: string;
    emailStudents: string;
    adharNoStudents: number;
    mobileNoStudents: number;
    genderStudents: string;
    dobStudents: Date;
    coachNameStudents: string;
    coachMoNoStudents: number;
    addressStudents: string;
    originalFileName: any;
    imageStudents:string;
    isFromEvent:number;
    eventId:number;
    ageGroup:number;
    category:string;
    courseType:string;
    School:string;
    BranchId:number;
    IsApprovedStudent:number;
} 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { LoginComponent,UnderConstructionComponent, EinitialComponent ,AdPrevVideoComponent,ImageCropperComponent } from './components';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [LoginComponent, UnderConstructionComponent,EinitialComponent, AdPrevVideoComponent, ImageCropperComponent, ImageCropperComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule  
  ],
  exports:[EinitialComponent,AdPrevVideoComponent,ImageCropperComponent]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { studentModal } from 'src/app/modules/public/component/students/students.modal';
import { constants, responder} from '../../common';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import {Http,Response} from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class StudRegisterService {
  constructor(private _router: Router, private HttpClient: HttpClient,private _http:Http) { }
  _responder: (any | responder);
  addUpdateStudentRegister(student: studentModal,returnString:string,isFromEvent:boolean,paramId:number):Observable<any> {
    let serviceRoute = constants.api + "/public/register";
    return this._http.post(serviceRoute, student)
              .map((res:Response)=><any>res.json());
  }

  getStudentFromAdhar(adharNo:number,isFromEvent:boolean):Observable<any>{
    let serviceRoute = constants.api + "/public/register/adhar?AdharStudent="+adharNo+"&&isFromEvent="+isFromEvent;
    return this._http.get(serviceRoute)
    .map((res:Response)=><any>res.json());             
  }
  getBranchList():Observable<any>{
    let serviceRoute = constants.api + "/public/branch";
    return this._http.get(serviceRoute)
    .map((res:Response)=><any>res.json());             
  }
}
 
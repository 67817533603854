import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  HomepageComponent, EventDetailComponent, EventComponent,EventFormComponent, GalleryComponent
} from './component';
import { RegisterComponent,NewEntryComponent } from './component/students';

const routes: Routes = [
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'homepage',
    component: HomepageComponent
  },  
  {
    path: 'events',
    component: EventComponent
  },
  {
    path: 'gallery',
    component: GalleryComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'newForm',
    component: NewEntryComponent
  },
  {
    path: 'event/:id',
    component: EventDetailComponent
  },
  {
    path: 'event/:success/:id',
    component: EventDetailComponent
  },
  {
    path: 'event-form/:id',
    component: EventFormComponent
  },
  {
    path: 'event-form/:success/:id',
    component: EventFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {constants,responder} from '../../common';
import {IImage} from '../../modules/public/model/IImage'; 

@Injectable({
  providedIn: 'root'
})
export class SliderService {
  
  constructor(private _router: Router,private http:HttpClient) { }

  _responder : (any | responder);

  getsliderdata(returnArray){
    // let returnArray  = new Array<IImage>();
    let iimageobj;
    this.http.get(constants.api + "/admin/homepage/slider").subscribe((res:any)=>{
        if(res['status'] == 200){
          res.data.forEach(element => {
              iimageobj  = new IImage();
              iimageobj.url = constants.api +'/'+ element.urlSliderSection.replace(new RegExp(/\134/g, 'g'), '/');
              iimageobj.caption = element.captionSliderSection;
              iimageobj.title = element.titleSliderSection
              returnArray.push(iimageobj);
          });          
        } 
        else{
          this._router.navigate(['/login'],{queryParams : {error:'invalid'}});
        }     
      }); 
  }
  getsliderdataStatic(){
    let pathFirst : string = "/assets/images/slider/";//"http://localhost:4200/assets/images/slider/";
    let returnArray : (string | IImage)[] = [];
    returnArray.push({url:pathFirst+'0.jpg',caption:'',title:''}); 
    returnArray.push({url:pathFirst+'1.jpg',caption:'',title:''}); 
    returnArray.push({url:pathFirst+'2.jpg',caption:'',title:''}); 
    returnArray.push({url:pathFirst+'3.jpg',caption:'',title:''}); 
    returnArray.push({url:pathFirst+'5.jpg',caption:'',title:''}); 
    returnArray.push({url:pathFirst+'6.jpg',caption:'',title:''}); 

    return returnArray;
  }
}

import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services';
import { eventModal } from 'src/app/modules/public/model/events.modal';
import { keyValueModal, constants } from 'src/app/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
 
  constructor(private _eventService : EventService,private router: Router,
    private route: ActivatedRoute,) { }
  events:Array<eventModal> = new Array<eventModal>();
  queryParam:keyValueModal = new keyValueModal();
  queryParamArray:Array<keyValueModal> = new Array<keyValueModal>();
  ngOnInit() { 
    console.log("event called");
    
    this.queryParam.key = "limit";
    this.queryParam.value = "2";
    this.queryParamArray.push(this.queryParam);
    this.events = this._eventService.getEventsStatic(this.queryParam);
  }

  navigatToMore(id:number,desc:string){
    console.log("navigation called ::: ID = ",id);   
    if(desc && desc != ''){
      this.router.navigate(['/event/'+id]);    
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Routes, Router, NavigationEnd } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { moduleType } from './common/struct';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit  { 
  getMOduleType : any;
  constructor (private url:LocationStrategy,private router: Router){
    
  } 
  title = 'dinamic';
  ngOnInit() {
    let urlPath = this.url.path();
    if(urlPath.indexOf('login') > -1)
    {
      this.getMOduleType =  moduleType.Login;
    }
    else if(urlPath.indexOf('admin') > -1){
      this.getMOduleType =  moduleType.Admin;
    }
    else{
      this.getMOduleType =  moduleType.Public;
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  }

}

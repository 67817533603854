import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from 'src/app/common';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
username:string="";
password:string="";
errorMessage = "";
  constructor(private _authService : AuthService, private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if(params['error']){
        this.errorMessage = "#Error: " + constants.invalidLogin;
      }
    });
    this._authService.clearLocalStorage();
  }
  login()
  {
    this._authService.loginAdmin(this.username,this.password).subscribe((res) => {
        if(res['status'] == 200){
        localStorage.setItem('token', res['data'][0]['token']);     
        localStorage.setItem('type', res['data'][0]['type']);   
        localStorage.setItem('userName', this.username); 
        this.router.navigate(['/admin/dashboard']);
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      } 
      else{
        this.router.navigate(['/login'],{queryParams : {error:'invalid'}});
      }  
    });    

  }
}

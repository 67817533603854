import { Component, OnInit } from '@angular/core';
import {IPlayerImages} from './IPlayerImages'
// import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
@Component({
  selector: 'app-players-slider',
  templateUrl: './players-slider.component.html',
  styleUrls: ['./players-slider.component.scss']
})
export class PlayersSliderComponent implements OnInit {

  index = 0;
  imageUrls: (string | IPlayerImages)[] = [];  

  constructor() { }
  ngOnInit(){
    this.imageUrls = [
      {url:'assets/img/players/g1.jpg',title:''},
      {url:'assets/img/players/g2.jpg',title:''},
      {url:'assets/img/players/g3.jpg',title:''},
      {url:'assets/img/players/g4.jpg',title:''},
      {url:'assets/img/players/g5.jpg',title:''},
      {url:'assets/img/players/g6.jpg',title:''},
      {url:'assets/img/players/g3.jpg',title:''},
      {url:'assets/img/players/g4.jpg',title:''},
      {url:'assets/img/players/g5.jpg',title:''},
      {url:'assets/img/players/g6.jpg',title:''},
    ]
    
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import 'hammerjs';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent,MenuComponent, FooterComponent  } from './common/components';
import { RoleGuard, AuthGuard } from './services';
import { EscapeHtmlPipe } from './common/pipes/escapeHtml.pipe';
import {HttpModule} from '@angular/http';
import {PublicModule} from './modules/public/public.module';
import {AdminModule} from './modules/admin/admin.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import {SharedModule} from './modules/shared/shared.module';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    EscapeHtmlPipe
  ],
  imports: [
    PublicModule, 
    //AdminModule,
    HttpModule,
    // SharedModule,
    BrowserModule,    
    AppRoutingModule,
    FormsModule,
    
    ReactiveFormsModule,
    NgxHmCarouselModule,
    HttpClientModule 
       
  ],
  providers:[AuthGuard,RoleGuard,{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  
})
export class AppModule { }

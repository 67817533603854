import { Component, OnInit } from '@angular/core';
import { bannerModal, constants, keyValueModal } from 'src/app/common';
import { Router, ActivatedRoute } from '@angular/router';
import { routerNgProbeToken } from '@angular/router/src/router_module';
import { HelperService } from 'src/app/services';
import { eventModal } from '../../../model/events.modal';
import { EventService } from 'src/app/services';
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _helper:HelperService,
    private _eventService : EventService 
  ) { }
  navigationLinks : Array<bannerModal>;
  events:Array<eventModal> = new Array<eventModal>();
  ngOnInit() {
    this.getBreadcrumb(); 
    console.log("event page called");  
    let eventObj;  
    // this._eventService.getEvents()
    // .subscribe((res) => {
    //   if (res['status'] == 200) {
    //     res.data.forEach(element => {
    //       eventObj = new eventModal();
    //       eventObj.idEventSection = element.idEventSection;
    //       eventObj.titleEventSection = element.titleEventSection;
    //       eventObj.descEventSection = element.descEventSection;
    //       eventObj.sortDescEventSection = element.sortDescEventSection;
    //       eventObj.agendaEventSection = element.agendaEventSection;
    //       eventObj.dateEventSection = element.dateEventSection;
    //       eventObj.lastDateOfEventSection = element.lastDateOfEventSection;
    //       eventObj.imageUrlEventSection = constants.api + '/' + element.imageUrlEventSection.replace(new RegExp(/\134/g, 'g'), '/');
    //       eventObj.prevYearVideoUrlEventSection = element.prevYearVideoUrlEventSection;
    //       eventObj.isActiveEventSection = element.isActiveEventSection;
    //       eventObj.employeeId = element.employeeId;
    //       eventObj.curDate = element.curDate;
    //       this.events.push(eventObj);
    //     });        
    //   }
    //   else {
    //     this.router.navigate(['/login'], { queryParams: { error: 'invalid' } });
    //   }
    // });
    let obj: keyValueModal = new keyValueModal();
    obj.key="event";
    obj.value = "100";
    Object.assign(this.events,this._eventService.getEventsStatic(obj))
    // this.events = this._eventService.getEventsStatic();
    
  }

  navigateTo(id){
    this.router.navigate(['/event/'+id]);
  }
  
  getBreadcrumb(){
    this.navigationLinks = new Array<bannerModal>();
    this._helper.getBreadcrumbs('Home|Events',this.navigationLinks);
  }
}

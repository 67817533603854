import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services';
// import {AdminModule} from './modules/admin/admin.module';
const routes: Routes = [{
  path: '',
  loadChildren:'./modules/public/public.module#PublicModule'
},
{
  path: 'admin',
 //loadChildren :()=>AdminModule
  loadChildren:'./modules/admin/admin.module#AdminModule',
   canActivate: [AuthGuard]
},
{
  path: 'login',
  loadChildren:'./modules/shared/shared.module#SharedModule',  
},
{
  path: '',
  redirectTo: '',
  pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

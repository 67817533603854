import { Component, OnInit, ViewChild, Input, Renderer2 } from '@angular/core';
import { ImageCropperComponent } from 'src/app/modules/shared/components';
import { ImageCroppedEvent } from 'src/app/modules/shared/components/image-cropper/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { StudRegisterService, HelperService, EventService } from 'src/app/services';
import { studentModal } from '../students.modal';
import { branchModal } from './branch.modal';
import { keyValueModal, constants } from 'src/app/common';
import { alertModal, bannerModal } from 'src/app/common/model';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-new-entry',
  templateUrl: './new-entry.component.html',
  styleUrls: ['./new-entry.component.css']
})
export class NewEntryComponent implements OnInit {
  navigationLinks : Array<bannerModal>;
  params: any;
  queryParam: keyValueModal = new keyValueModal();
  queryParamArray: Array<keyValueModal> = new Array<keyValueModal>();
  adharNoStudentsFind:number;
  renderer: Renderer2;
  successMessage: string = '';
  showCropper = false;
  studRegData: studentModal = new studentModal();
  alert: alertModal = new alertModal();
  imageChangedEvent: any = '';
  profileImageinvalid:boolean = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  showFromAfterAdharFind:boolean = false;
  selectedCateImage:String="";
  AgreeRulesAndRegulations:boolean = false;
  branchList:Array<branchModal> = new Array<branchModal>();
  constructor(private _router: Router,private activatedRoute: ActivatedRoute, private _eventService: EventService, private _StudRegisterService: StudRegisterService, private _helper: HelperService,) {
    this.params = this.activatedRoute.snapshot.params;
  }

  resetAll(){
    this.studRegData = new studentModal();
    this.profileImageinvalid = false;
    this.showFromAfterAdharFind = false;
    this.selectedCateImage = "";
    this.AgreeRulesAndRegulations = false;
  }
  ngOnInit() {
    if (this.params.id) {
      this.queryParam.key = "id";
      this.queryParam.value = this.params.id;
      this.queryParamArray.push(this.queryParam);
    }
    this.getBreadcrumb();
    // this.getBranchList();
  }
  getBreadcrumb(){
    this.navigationLinks = new Array<bannerModal>();
    this._helper.getBreadcrumbs("Home|NewRegister",this.navigationLinks);
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.studRegData.imageStudents = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady() {
    console.log('Cropper ready')
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }
  registerStudent() {
    if (this.studRegData.imageStudents) {
     
      this.studRegData.isFromEvent = 0;
       
      this._StudRegisterService.addUpdateStudentRegister(this.studRegData, this.successMessage, false, this.params.id)
        .subscribe((res) => {
          if (res['status'] == 200) {
            console.log("response",[res])
            this.alert = new alertModal();
            this.alert.type = "success";
            this.alert.message = constants.recordSaved;
            this.clearControls();
            let headerStatic = document.getElementById('headerStatic');
            headerStatic.focus(); 
            var elmnt = document.getElementById("headerStatic");
            elmnt.scrollIntoView();  
            if(res.data.receipt){
             window.open(constants.api +'/'+ res.data.receipt);       
            }
          }
          else {
            this.alert = new alertModal();
            this.alert.type = "danger";
            this.alert.message = constants.somethingWrong;
            let headerStatic = document.getElementById('headerStatic');
            headerStatic.focus();
          }
        });
    }
    else{
      this.profileImageinvalid = true;
    }
  }
  updateGender(value) {
    this.studRegData.genderStudents = value;
  }
  dobChanged() {
    
  }
  categoryChanged(obj){
    
    if(obj.target.selectedIndex == 0){
    this.selectedCateImage = "assets/img/category/tenacity.png";
    this.studRegData.category = "Tenacity";
    }
    else if(obj.target.selectedIndex == 1){
      this.selectedCateImage = "assets/img/category/quad.png";
      this.studRegData.category = "Quad";
      }
      else if(obj.target.selectedIndex == 2){
        this.selectedCateImage = "assets/img/category/inline.png";
        this.studRegData.category = "Inline";
        }
  }
  clearControls() {   
    this.studRegData = new studentModal();
    this.studRegData.isFromEvent = 0;
    this.imageChangedEvent = '';
    this.showFromAfterAdharFind = false;
  }
  
  close() {
    this.alert = new alertModal();
  }

  findAdharNo(){
    let searchAdharNo = this.adharNoStudentsFind;
    this.studRegData = new studentModal();
    if(this.adharNoStudentsFind){
      this._StudRegisterService.getStudentFromAdhar(this.adharNoStudentsFind,false)
        .subscribe((res) => {
          if (res['status'] == 200) {
             if(res['data']){
               if(Object(res['data']).length > 0){
                    Object.assign(this.studRegData,res['data'][0]);
                    this.studRegData.imageStudents = constants.api + '/' + this.studRegData.imageStudents;
                    this.dobChanged();
               }
               else{
                this.studRegData.adharNoStudents = searchAdharNo;
               }
             }
             else{this.studRegData.adharNoStudents = searchAdharNo;}
          }
        });
        this.showFromAfterAdharFind = true;
    }
    this.studRegData.adharNoStudents = searchAdharNo;
    this.getBranchList();
  }
 
  AgreeRulesAndRegulations_change(state){
    this.AgreeRulesAndRegulations = state.currentTarget.checked;
  }
getBranchList(){
  this.branchList = new Array<branchModal>();
  this._StudRegisterService.getBranchList()
        .subscribe((res) => {
          if (res['status'] == 200) {
             if(res['data']){
               if(Object(res['data']).length > 0){
                    Object.assign(this.branchList,res['data'])
               }
               else{
                
               }
             }
             
          }
        });
}

}

import { Component, OnInit, ViewChild, Input, Renderer2 } from '@angular/core';
import { ImageCropperComponent } from 'src/app/modules/shared/components';
import { ImageCroppedEvent } from 'src/app/modules/shared/components/image-cropper/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { StudRegisterService, HelperService, EventService } from 'src/app/services';
import { studentModal } from './students.modal';
import { eventModal } from '../../model';
import { keyValueModal, constants } from 'src/app/common';
import { alertModal } from 'src/app/common/model';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {
  params: any;
  events: Array<eventModal> = new Array<eventModal>();
  queryParam: keyValueModal = new keyValueModal();
  queryParamArray: Array<keyValueModal> = new Array<keyValueModal>();
  adharNoStudentsFind:number;
  renderer: Renderer2;
  @Input() isFromEvent: boolean = false;
  eventId: number = 0;
  ageCalculationDate: Date = null;
  calculateAgeGroupFrom: any;
  imageChangedEvent: any = '';
  //croppedImage: any = '';
  successMessage: string = '';
  showCropper = false;
  studRegData: studentModal = new studentModal();
  alert: alertModal = new alertModal();
  profileImageinvalid:boolean = false;
  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
  showFromAfterAdharFind:boolean = false;
  selectedCateImage:String="";
  AgreeRulesAndRegulations:boolean = false;
  constructor(private _router: Router,private activatedRoute: ActivatedRoute, private _eventService: EventService, private _StudRegisterService: StudRegisterService, private _helper: HelperService) {
    this.params = this.activatedRoute.snapshot.params;
  }
  resetAll(){
    this.studRegData = new studentModal();
    this.profileImageinvalid = false;
    this.showFromAfterAdharFind = false;
    this.selectedCateImage = "";
    this.AgreeRulesAndRegulations = false;
  }
  ngOnInit() {
    if (this.params.id) {
      this.queryParam.key = "id";
      this.queryParam.value = this.params.id;
      this.queryParamArray.push(this.queryParam);
      let eventObj;
      this._eventService.getEvents(this.queryParamArray)
      .subscribe((res) => {
        if (res['status'] == 200) {
          res.data.forEach(element => {
            eventObj = new eventModal();
            eventObj.idEventSection = element.idEventSection;
            eventObj.titleEventSection = element.titleEventSection;
            eventObj.descEventSection = element.descEventSection;
            eventObj.sortDescEventSection = element.sortDescEventSection;
            eventObj.agendaEventSection = element.agendaEventSection;
            eventObj.dateEventSection = element.dateEventSection;
            eventObj.lastDateOfEventSection = element.lastDateOfEventSection;
            eventObj.imageUrlEventSection = constants.api + '/' + element.imageUrlEventSection.replace(new RegExp(/\134/g, 'g'), '/');
            eventObj.prevYearVideoUrlEventSection = element.prevYearVideoUrlEventSection;
            eventObj.isActiveEventSection = element.isActiveEventSection;
            eventObj.employeeId = element.employeeId;
            eventObj.curDate = element.curDate;
            eventObj.ageCalculationEventSection = element.ageCalculationEventSection;
            this.events.push(eventObj);
          });        
        }
        else {
          this._router.navigate(['/login'], { queryParams: { error: 'invalid' } });
        }
      });
      setTimeout(() => {
        if(this.isFromEvent){
        this.studRegData.isFromEvent = 1;}
        else{this.studRegData.isFromEvent = 0;}
        if (this.events.length > 0) {
          this.studRegData.eventId = this.events[0].idEventSection;
          this.eventId = this.events[0].idEventSection;
          this.ageCalculationDate = this.events[0].ageCalculationEventSection;
        }

        if (!this.ageCalculationDate) {
          let curDate = new Date;
          this.calculateAgeGroupFrom = new Date(curDate.getFullYear() + '-01-01');
        }
        else {
          this.calculateAgeGroupFrom = this.ageCalculationDate;
        }
        //this.calculateAgeGroupFrom = new Date("2019-01-01");
      }, 3000);

    }
  }
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    //this.croppedImage = event.base64;
    this.studRegData.imageStudents = event.base64;
    //this.studRegData.originalFileName = "image/one.png";
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded')
  }
  cropperReady() {
    console.log('Cropper ready')
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }
  registerStudent() {
    // this.studRegData.imageStudents = event.base64;
    if (this.studRegData.imageStudents && this.studRegData.ageGroup) {
      if(this.isFromEvent){
        this.studRegData.isFromEvent = 1;}
        else{this.studRegData.isFromEvent = 0;}
        
      this._StudRegisterService.addUpdateStudentRegister(this.studRegData, this.successMessage, this.isFromEvent, this.params.id)
        .subscribe((res) => {
          if (res['status'] == 200) {
            console.log("response",[res])
            this.alert = new alertModal();
            this.alert.type = "success";
            this.alert.message = constants.recordSaved;
            this.clearControls();
            let headerStatic = document.getElementById('headerStatic');
            headerStatic.focus(); 
            var elmnt = document.getElementById("headerStatic");
            elmnt.scrollIntoView();  
            if(res.data.receipt){
             window.open(constants.api +'/'+ res.data.receipt);       
            }
          }
          else {
            this.alert = new alertModal();
            this.alert.type = "danger";
            this.alert.message = constants.somethingWrong;
            let headerStatic = document.getElementById('headerStatic');
            headerStatic.focus();
          }
        });
    }
    else{
      this.profileImageinvalid = true;
    }
  }
  updateGender(value) {
    this.studRegData.genderStudents = value;
  }
  dobChanged() {
    this.getAge();
  }
  categoryChanged(obj){
    
    if(obj.target.selectedIndex == 0){
    this.selectedCateImage = "assets/img/category/tenacity.png";
    this.studRegData.category = "Tenacity";
    }
    else if(obj.target.selectedIndex == 1){
      this.selectedCateImage = "assets/img/category/quad.png";
      this.studRegData.category = "Quad";
      }
      else if(obj.target.selectedIndex == 2){
        this.selectedCateImage = "assets/img/category/inline.png";
        this.studRegData.category = "Inline";
        }
  }
  clearControls() {   
    this.studRegData = new studentModal();
    this.studRegData.eventId = this.eventId;
    if(this.isFromEvent){this.studRegData.isFromEvent = 1;}else{this.studRegData.isFromEvent = 0;}
    this.imageChangedEvent = '';
    this.showFromAfterAdharFind = false;
  }
  getAge() {
    let today:any = new Date(this.calculateAgeGroupFrom);
    let birthDate:any = new Date(this.studRegData.dobStudents);
    //let days = ((today - birthDate)/(1000*60*60*24));
    //let age = days/365;
    let age = (today.getFullYear() - birthDate.getFullYear());
    // let m = today.getMonth() - birthDate.getMonth();
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //   age = age - 1;
    // }
    let ageGroup = 0;
         if (age < 5)               { ageGroup = 1; }
    else if (age > 4 && age < 7)    { ageGroup = 2; }
    else if (age > 6 && age < 9)    { ageGroup = 3; }
    else if (age > 8 && age < 11)   { ageGroup = 4; }
    else if (age > 10 && age < 14)  { ageGroup = 5; }
    else if (age > 13 && age < 17)  { ageGroup = 6; }
    else if (age > 16 && age < 30)  { ageGroup = 7; }
    else if (age > 29)              { ageGroup = 8; }
    this.studRegData.ageGroup = ageGroup;
  }
  close() {
    this.alert = new alertModal();
  }

  findAdharNo(){
    let searchAdharNo = this.adharNoStudentsFind;
    if(this.adharNoStudentsFind){
      this._StudRegisterService.getStudentFromAdhar(this.adharNoStudentsFind,this.isFromEvent)
        .subscribe((res) => {
          if (res['status'] == 200) {
             if(res['data']){
               if(Object(res['data']).length > 0){
                    Object.assign(this.studRegData,res['data'][0]);
                    this.studRegData.imageStudents = constants.api + '/' + this.studRegData.imageStudents;
                    this.dobChanged();
               }
               else{
                this.studRegData.adharNoStudents = searchAdharNo;
               }
             }
             else{this.studRegData.adharNoStudents = searchAdharNo;}
          }
        });
        this.showFromAfterAdharFind = true;
    }
    this.studRegData.adharNoStudents = searchAdharNo;
  }
 
  AgreeRulesAndRegulations_change(state){
    this.AgreeRulesAndRegulations = state.currentTarget.checked;
  }
} 

import { Injectable } from '@angular/core';
import { bannerModal, constants } from '../common';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private http: HttpClient) { }
  _bannerModal: bannerModal;
  menuSaperatorArray: Array<string>;

  getBreadcrumbs(menuString: string, navigationLinks: Array<bannerModal>, id: number = 0) {
    menuString = menuString.toLowerCase();
    this.menuSaperatorArray = menuString.split('|');
    if (this.menuSaperatorArray && this.menuSaperatorArray.length > 0) {
      for (let index = 0; index < this.menuSaperatorArray.length; index++) {
        let element = this.menuSaperatorArray[index];
        this._bannerModal = new bannerModal();
        let isNotLastIndex = true;
        if (index === this.menuSaperatorArray.length - 1) {
          this._bannerModal.routeLink = "javascript:void(0)";
          isNotLastIndex = false;
        }
        switch (element) {
          case 'home':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/homepage"; }
            this._bannerModal.value = "Home";
            break;
          case 'events':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/events"; }
            this._bannerModal.value = "Events";
            break;
          case 'event-desc':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/event/" + id; }
            this._bannerModal.value = "Event Description";
            break;
            case 'event-form':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/event-form/" + id; }
            this._bannerModal.value = "Event Form";
            break;
            case 'gallery':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/gallery/";}
            this._bannerModal.value = "Gallery";
            break;
            case 'register':
            if (isNotLastIndex) { this._bannerModal.routeLink = "/#/register/";}
            this._bannerModal.value = "Register";
            break;
            case 'newregister':
                if (isNotLastIndex) { this._bannerModal.routeLink = "/#/newForm/";}
                this._bannerModal.value = "New Student Registration";
                break;
          default:
            break;
        }
        navigationLinks.push(this._bannerModal);
      }
    }
  }

  getServerDate(returnDateObj){
    let serviceRoute = constants.api + "/public/ServerDate";
    this.http.get(serviceRoute).subscribe((res: any) => {
      if (res['status'] == 200) {
        returnDateObj = new Date(res.data[0].serverDate.toString());
      }
      else {
        returnDateObj =  null;
      }
    });
  }

  async getAgeCaulationDate(returndate): Promise<Date>{
    try{
    let serviceRoute = constants.api + "/public/ServerDate";
    
    await this.http.get(serviceRoute).subscribe((res: any) => {
      let dateToreturn = null;
      if (res['status'] == 200) {
        let dateRes = new Date(res.data[0].serverDate.toString());        
        dateToreturn = new Date(dateRes.getFullYear() +'-01-01');
      }
      else{
        dateToreturn =  null;
      }
      returndate = dateToreturn;  
      return returndate;    
    });
  }
  catch{return null;}
    // return await dateToreturn;
       
  }

}

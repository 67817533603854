
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {constants,responder} from '../../common';
import {Http,Response} from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private message: string;

  constructor(private _router: Router,private http:HttpClient,private _http:Http) { }

  _responder : (any | responder);

  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    localStorage.clear();
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    return localStorage.getItem('token') != null && !this.isTokenExpired();
  }

  // simulate jwt token is valid
  // https://github.com/theo4u/angular4-auth/blob/master/src/app/helpers/jwt-helper.ts
  isTokenExpired(): boolean {
    return false;
  }

  loginAdmin(userName,password): Observable<any> {
    let serviceRoute = constants.api + "/public/access/?userName="+userName+"&password="+password;
    return this._http.get(serviceRoute)
    .map((res:Response)=><any>res.json());
  }

  //   this.http.get(constants.api + ).subscribe((res:any)=>{
  //     if(res['status'] == 200){
  //       localStorage.setItem('token', res['data'][0]['token']);     
  //       localStorage.setItem('type', res['data'][0]['type']);   
  //       localStorage.setItem('userName', userName); 
  //       this._router.navigate(['/admin/dashboard']);
  //       //document.location.reload();
  //     } 
  //     else{
  //       this._router.navigate(['/login'],{queryParams : {error:'invalid'}});
  //     }     
  //   });   
  // }

  login(userName,password): void {
    this.http.get(constants.api + "/public/access/?userName="+userName+"&password="+password).subscribe((res:any)=>{
      if(res['status'] == 200){
        localStorage.setItem('token', res['data'][0]['token']);     
        localStorage.setItem('type', res['data'][0]['type']);  
        localStorage.setItem('userName', userName);           
      }
      else{
        this._router.navigate(['/admin/dashboard']);
      }
  });
}

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('type');
    localStorage.removeItem('userName');    
    this._router.navigate(['/login']);
  }
  clearLocalStorage(): void {
    this.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('type');
    localStorage.removeItem('userName');
  }
  getRole(): string{
    let role = localStorage.getItem('type');
    return role;
  }
  

}
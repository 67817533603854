import { Component, OnInit } from '@angular/core';
import { bannerModal,constants } from 'src/app/common';
import { HelperService } from 'src/app/services';
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  htmlGalaryInnerText: string;
  gallayObject: Array<any> = new Array<any>();
  navigationLinks : Array<bannerModal>;
  constructor(private _helper:HelperService,) { }

  ngOnInit() {
    this.getBreadcrumb();
    var path = "assets/img/gallery/";
    var arrayOfGallary = [];
    var ext = ".jpeg";
    var arrayGalary = ["1", "2", "3", "4", "9", "5", "6", "7", "8", "10", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "11", "22", "23", "24", "25", "26", "27", "28", "29", "30"];
    var obj = [];
    var galaryArrayLength = arrayGalary.length;
    let isFirst = false;
    for (let index = 0; index < arrayGalary.length; index++) {

        if(galaryArrayLength >= 3 && !isFirst ){
          obj.push({url: path+'ad('+arrayGalary[index]+')'+ext});
          if(obj.length == 3){
            isFirst = true;
            galaryArrayLength = galaryArrayLength - 3;
            this.gallayObject.push(obj);
            obj = [];
          }
        }
        if(galaryArrayLength >= 2 && isFirst){
          obj.push({url: path+'ad('+arrayGalary[index]+')'+ext});
          if(obj.length == 2){
            isFirst = false;
            galaryArrayLength = galaryArrayLength - 2;
            this.gallayObject.push(obj);
            console.log("check :: ",this.gallayObject);
          
            obj = [];
          }
        }
    }
  }
  getMyStyles(obj){
    // let myStyles = {"background-image": "url('"+constants.webHost +obj+"')" };
    let myStyles = {"background-image": "url('"+obj+"')" };

   return myStyles;
  }
  getBreadcrumb(){
    this.navigationLinks = new Array<bannerModal>();
    this._helper.getBreadcrumbs('Home|Gallery',this.navigationLinks);
  }
}

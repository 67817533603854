import { Component, OnInit } from '@angular/core';
import { bannerModal, keyValueModal, constants } from 'src/app/common';
import { HelperService, EventService } from 'src/app/services';
import { eventModal } from '../../../model/events.modal';
import { ActivatedRoute, RouterLink, Router } from '@angular/router';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {
  params: any;
  successParam: string;
  constructor(private _router: Router, private _helper: HelperService, private _eventService: EventService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.params = this.activatedRoute.snapshot.params;
    console.log("params = ", this.params.id);
  }
  navigationLinks: Array<bannerModal>;
  events: Array<eventModal> = new Array<eventModal>();
  queryParam: keyValueModal = new keyValueModal();
  queryParamArray: Array<keyValueModal> = new Array<keyValueModal>();
  ngOnInit() {
    this.getBreadcrumb();


    this.queryParam.key = "id";
    this.queryParam.value = this.params.id;
    if (this.params.suceess) {
      if (this.params.suceess == true) {
        this.successParam = constants.somethingWrong;
      }
    }
    let eventObj;
    this.queryParamArray.push(this.queryParam);
    let Tempobj: keyValueModal = new keyValueModal();
    Tempobj.key = 'eve';
    Tempobj.value = "100";
    let eventObjArray: eventModal[] = this._eventService.getEventsStatic(Tempobj);
    
    for (let index = 0; index < eventObjArray.length; index++) {
      const element = eventObjArray[index];
      if (element.idEventSection == parseInt(this.queryParam.value)) {
        this.events.push(element);
        break;
      }
    }
    // this._eventService.getEvents(this.queryParamArray)
    // .subscribe((res) => {
    //   if (res['status'] == 200) {
    //     res.data.forEach(element => {
    //       eventObj = new eventModal();
    //       eventObj.idEventSection = element.idEventSection;
    //       eventObj.titleEventSection = element.titleEventSection;
    //       eventObj.descEventSection = element.descEventSection;
    //       eventObj.sortDescEventSection = element.sortDescEventSection;
    //       eventObj.agendaEventSection = element.agendaEventSection;
    //       eventObj.dateEventSection = element.dateEventSection;
    //       eventObj.lastDateOfEventSection = element.lastDateOfEventSection;
    //       eventObj.imageUrlEventSection = constants.api + '/' + element.imageUrlEventSection.replace(new RegExp(/\134/g, 'g'), '/');
    //       eventObj.prevYearVideoUrlEventSection = element.prevYearVideoUrlEventSection;
    //       eventObj.isActiveEventSection = element.isActiveEventSection;
    //       eventObj.employeeId = element.employeeId;
    //       eventObj.curDate = element.curDate;
    //       this.events.push(eventObj);
    //     });        
    // }
    // else {
    //   this._router.navigate(['/login'], { queryParams: { error: 'invalid' } });
    // }
    // });  



  }
  getBreadcrumb() {
    this.navigationLinks = new Array<bannerModal>();
    this._helper.getBreadcrumbs('Home|Events|Event-Desc', this.navigationLinks);
  }
  navigateToForm() {
    this.router.navigate(['/event-form', this.params.id]);
  }
}

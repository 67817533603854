import { Component, OnInit } from '@angular/core';
import { keyValueModal, bannerModal } from 'src/app/common/model';
import { ActivatedRoute, Router } from '@angular/router';
import { eventModal } from '../../../model';
import { EventService, HelperService } from 'src/app/services';
import { constants } from '../../../../../common/constants';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.css']
})
export class EventFormComponent implements OnInit {
  params:any;
  events:Array<eventModal> = new Array<eventModal>();
  queryParam:keyValueModal = new keyValueModal();
  queryParamArray:Array<keyValueModal> = new Array<keyValueModal>();
  navigationLinks : Array<bannerModal>;
  successParam:string ='';
  constructor(private _router: Router,private _helper:HelperService,private _eventService : EventService,private activatedRoute: ActivatedRoute) { 
    this.params = this.activatedRoute.snapshot.params;
  }

  ngOnInit() {
    this.queryParam.key = "id";
      this.queryParam.value = this.params.id;
      if(this.params.suceess){
        if(this.params.suceess == false){
          this.successParam = constants.somethingWrong;
        }
      }
      let eventObj;
      this.queryParamArray.push(this.queryParam);
      this._eventService.getEvents(this.queryParamArray)
      .subscribe((res) => {
        if (res['status'] == 200) {
          res.data.forEach(element => {
            eventObj = new eventModal();
            eventObj.idEventSection = element.idEventSection;
            eventObj.titleEventSection = element.titleEventSection;
            eventObj.descEventSection = element.descEventSection;
            eventObj.sortDescEventSection = element.sortDescEventSection;
            eventObj.agendaEventSection = element.agendaEventSection;
            eventObj.dateEventSection = element.dateEventSection;
            eventObj.lastDateOfEventSection = element.lastDateOfEventSection;
            eventObj.imageUrlEventSection = constants.api + '/' + element.imageUrlEventSection.replace(new RegExp(/\134/g, 'g'), '/');
            eventObj.prevYearVideoUrlEventSection = element.prevYearVideoUrlEventSection;
            eventObj.isActiveEventSection = element.isActiveEventSection;
            eventObj.employeeId = element.employeeId;
            eventObj.curDate = element.curDate;
            this.events.push(eventObj);
          });        
        }
        else {
          this._router.navigate(['/login'], { queryParams: { error: 'invalid' } });
        }
      }); 
      this.getBreadcrumb(); 
  }

  getBreadcrumb(){
    this.navigationLinks = new Array<bannerModal>();
    this._helper.getBreadcrumbs("Home|Events|Event-Desc|Event-Form",this.navigationLinks,this.params.id);
  }
} 
